<template>
  <div>
    <h3 class="mb-1">
      {{ $t('Pay') }}
    </h3>

    <b-card
      class="mt-1"
    >
      <b-card-title>
        <b-row>
          <b-col
            cols="4"
          >
            <h3>
              {{ $t('check No') }}
            </h3>
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                :placeholder="$t('Type to Search')"
                @keyup.enter="onEnter"
              />
            </b-input-group>
          </b-col>
          <b-col
            cols="2"
          />
          <b-col
            cols="4"
          >
            <h3>
              {{ $t('Chassis No') }}
            </h3>
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter1"
                type="search"
                :placeholder="$t('Type to Search')"
                @keyup.enter="onEnter1"
              />
            </b-input-group>
            <b-col
              cols="1"
            />
          </b-col></b-row>
      </b-card-title>
      <b-row
        v-if="items[0]"
      >
        <b-card :title="$t('owwner')">
          <b-row
            v-if="items[0]"
          >
            <b-col
              v-for="el in ['name', 'phone','identify', 'identification_type']"
              :key="el"
              cols="3"
            >
              <b-form-group>
                {{ $t(el) }}
                <b-form-input
                  plaintext
                  :value="items[0]['owner_data'][el]"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card :title="$t('car')">
          <b-row
            v-if="items[0]"
          >
            <b-col
              v-for="el in [ 'manufacturer','model','car_type','motor_power']"
              :key="el"
              cols="3"
            >
              <b-form-group>
                {{ $t(el) }}
                <b-form-input
                  plaintext
                  :value="$t(items[0]['car_data'][el])"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            v-else
            class="text-center"
          >
            البيانات غير متاحة
          </div>
        </b-card>
        <b-card :title="$t('Checks')">
          <b-row
            v-if="items[0]"
          >
            <b-col
              v-for="el in ['id','chassis_number','color','contract_number','expiration_date','km']"
              :key="el"
              cols="3"
            >
              <b-form-group>
                {{ $t(el) }}
                <b-form-input
                  v-if="el !== 'chassis_number'"
                  plaintext
                  :value="items[0][el]"
                />
                <b-form-input
                  v-if="el === 'chassis_number'"
                  plaintext
                  :value="items[0]['checkin_data'][el]"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end mt-2"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            per-page="10"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >

        <b-card
          :title="$t('Create New Owner')"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            ><label>{{ $t('please select...') }}</label>
              <v-select
                v-model="payer.owner"
                :options="Owners"
                :reduce="Owners => Owners.id"
                label="name"
              />
              <small
                v-if="errors.owner"
                class="text-danger"
              >{{ $t(errors.owner[0]) }}</small>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="payer.name"
                  class="mt-2"
                  :state="errors.name ?false:null"
                  :placeholder="$t('Name')"
                />
                <small
                  v-if="errors.name"
                  class="text-danger"
                >{{ $t(errors.name[0]) }}</small>
                <label>{{ $t('Name') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="payer.phone"
                  class="mt-2"
                  :state="errors.phone ?false:null"
                  :placeholder="$t('Phone')"
                />
                <small
                  v-if="errors.phone"
                  class="text-danger"
                >{{ $t(errors.phone[0]) }}</small>
                <label>{{ $t('Phone') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="payer.identify"
                  class="mt-2"
                  :state="errors.identify ?false:null"
                  :placeholder="$t('identifyNo')"
                />
                <small
                  v-if="errors.identify"
                  class="text-danger"
                >{{ $t(errors.identify[0]) }}</small>
                <label>{{ $t('identifyNo') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Identification_type') }}</label>
                <v-select
                  v-model="payer.identification_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="types=>types.key"
                  :options="types"
                />
                <small
                  v-if="errors.identification_type"
                  class="text-danger"
                >{{ $t(errors.identification_type[0]) }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-textarea
                  v-model="payer.adress"
                  class="mt-2"
                  :state="errors.adress ?false:null"
                  :placeholder="$t('adress')"
                />
                <small
                  v-if="errors.adress"
                  class="text-danger"
                >{{ $t(errors.adress[0]) }}</small>
                <label>{{ $t('adress') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-textarea
                  v-model="payer.currentAdres"
                  class="mt-2"
                  :state="errors.currentAdres ?false:null"
                  :placeholder="$t('currentAdres')"
                />
                <small
                  v-if="errors.currentAdres"
                  class="text-danger"
                >{{ $t(errors.currentAdres[0]) }}</small>
                <label>{{ $t('currentAdres') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="payer.placeNo"
                  class="mt-2"
                  :state="errors.placeNo ?false:null"
                  :placeholder="$t('placeNo')"
                />
                <small
                  v-if="errors.placeNo"
                  class="text-danger"
                >{{ $t(errors.placeNo[0]) }}</small>
                <label>{{ $t('placeNo') }}</label>
              </div>
            </b-col>

          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-row>

          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <div class="form-label-group">
                      <b-form-input
                        v-model="notes"
                        class="mt-2"
                        :state="errors.notes ?false:null"
                        :placeholder="$t('notes')"
                      />
                      <small
                        v-if="errors.notes"
                        class="text-danger"
                      >{{ $t(errors.notes[0]) }}</small>
                      <label>{{ $t('notes') }}</label>
                    </div>
                  </b-col>
                  <b-card :title="$t('invoice')">
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <div class="form-label-group">
                        <cleave
                          v-model="fee"
                          class="form-control mt-2"
                          :raw="false"
                          :options="{
                            numeral: true,
                            numeralThousandsGroupStyle: 'none',
                            numeralDecimalScale: 0,
                          }"
                          :placeholder="$t('pay_fee')"
                          :state="errors.pay_fee ?false:null"
                          :class="errors.pay_fee ?'is-invalid':null"
                          disable
                        />
                        <small
                          v-if="errors.pay_fee"
                          class="text-danger"
                        >{{ $t(errors.pay_fee[0]) }}</small>
                        <label>{{ $t('pay_fee') }}</label>
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <div class="form-label-group">
                        <cleave
                          v-model="other_fee"
                          class="form-control mt-2"
                          :raw="false"
                          :options="{
                            numeral: true,
                            numeralThousandsGroupStyle: 'none',
                            numeralDecimalScale: 0,
                          }"
                          :placeholder="$t('other_fee')"
                          :state="errors.other_fee ?false:null"
                          :class="errors.other_fee ?'is-invalid':null"
                          disable
                        />
                        <small
                          v-if="errors.other_fee"
                          class="text-danger"
                        >{{ $t(errors.other_fee[0]) }}</small>
                        <label>{{ $t('other_fee') }}</label>
                      </div>
                    </b-col>
                    {{ $t('Total') }} : {{ Total }}<br>

                  </b-card>
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-primary"
                    @click="create1"
                  >
                    {{ $t('Print and Create') }}
                  </b-button>
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-success"
                    @click="create"
                  >
                    {{ $t('change Owner') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
            <b-card
              id="printMe"
              style="visibility: hidden;"
            >
              <div style="width: 100%!important; height: 100%; background-color: white; position: relative; top: 0;right: 0;left: 0;bottom: 0;">
                <img
                  src="@/assets/images/logo/logo.jpg"
                  style="width: 80px;
            height: 86px;
position: fixed;top:0px;left: 0px;"
                >
                <h3 style="text-align: right; right: 78%!important; color: black;position: fixed;top:80px; ">
                  {{ currentDateTime() }} :&nbsp; التاريخ
                </h3>
                <h3
                  v-if="items[0]"
                  style="text-align: right; right: 0%!important;color: black;position: fixed;top:130px; "
                >
                  {{ items[0].no }}: رقم  المركبة
                </h3>

                <h2
                  style="position: fixed;
                                top: 0px;
                                right: 0px;
                                text-align: right;
                               color: black; font-weight: bold;
                                font-family: 'Abdoullah Ashgar EL-kharef';"
                >المؤسسة العامة للنقل<br>مديرية  {{ user.company_data.name }}
                </h2>
                <h1
                  style="position: fixed;
                                top: 0px;
                                right: 30%;
                                left: 30%;
                                width: 40%;
                                text-align: center;
                               color: black; font-weight: bold;
                                font-family: 'Abdoullah Ashgar EL-kharef';"
                >صك بيع مركبة قطعي<br>
                </h1>
                <h3 style="text-align: right; right: 0%!important;color: black;position: fixed;top:160px; ">
                  مواصفات المركبة
                  <hr style="margin-top: 0px!important;">
                </h3>
                <h4
                  style="text-align: right; right: 0%!important;color: black;position: fixed;top:190px; width: 33%"
                >
                  &nbsp;:   الصانع
                </h4>
                <h4
                  v-if="this.items[0]"
                  style="text-align: center; right: 0%!important;color: black;position: fixed;top:190px; width: 33%"
                >
                  {{ items[0].car_data.manufacturer }}
                </h4>
                <h4
                  style="text-align: right; right: 33%!important;color: black;position: fixed;top:190px; width: 33%"
                >

                  &nbsp; :   الطراز
                </h4>
                <h4
                  v-if="this.items[0]"
                  style="text-align: center; right: 33%!important;color: black;position: fixed;top:190px; width: 33%"
                >
                  {{ items[0].car_data.model }}
                </h4>
                <h4
                  style="text-align: right; right: 66%!important;color: black;position: fixed;top:190px; width: 33%"
                >

                  &nbsp;  : النوع

                </h4>
                <h4
                  v-if="this.items[0]"
                  style="text-align: center; right: 66%!important;color: black;position: fixed;top:190px; width: 33%"
                >
                  {{ $t(items[0].car_data.car_type) }}
                </h4>
                <h4
                  style="text-align: right; right: 0%!important;color: black;position: fixed;top:220px; width: 25%"
                >
                  &nbsp;:   سنة الصنع
                </h4>
                <h4
                  v-if="this.items[0]"
                  style="text-align: center; right: 0%!important;color: black;position: fixed;top:220px; width: 25%"
                >
                  {{ items[0].checkin_data.created_year }}
                </h4>
                <h4
                  style="text-align: right; right: 25%!important;color: black;position: fixed;top:220px; width: 25%"
                >

                  &nbsp; :   سنة التسجيل الأولى
                </h4>
                <h4
                  v-if="this.items[0]"
                  style="text-align: center; right: 30%!important;color: black;position: fixed;top:220px; width: 25%"
                >
                  {{ items[0].checkin_data.check_in_date.slice(0,4) }}
                </h4>
                <h4
                  style="text-align: right; right: 50%!important;color: black;position: fixed;top:220px; width: 25%"
                >

                  &nbsp;  : اللون
                </h4>
                <h4
                  v-if="this.items[0]"
                  style="text-align: center; right: 50%!important;color: black;position: fixed;top:220px; width: 25%"
                >
                  {{ $t(items[0].color) }}
                </h4>
                <h4
                  style="text-align: right; right: 75%!important;color: black;position: fixed;top:220px; width: 25%"
                >
                  &nbsp;   : عدد المقاعد
                </h4>
                <h4
                  v-if="this.items[0]"
                  style="text-align: center; right: 75%!important;color: black;position: fixed;top:220px; width: 25%"
                >
                  {{ $t(items[0].car_data.passenger) }}
                </h4>

                <h4
                  style="text-align: right; right: 0%!important;color: black;position: fixed;top:250px; width: 50%"
                >
                  &nbsp; :   رقم الهيكل
                </h4>
                <h3
                  v-if="this.items[0]"
                  style="text-align: center; right: 0%!important;color: black;position: fixed;top:250px; width: 50%"
                >
                  {{ this.items[0].checkin_data.chassis_number }}
                </h3>
                <h4
                  style="text-align: right; right: 50%!important;color: black;position: fixed;top:250px; width: 50%"
                >
                  &nbsp; :    رقم المحرك
                </h4>
                <h3
                  style="text-align: right; right: 0%!important;color: black;position: fixed;top:285px; width: 50%"
                >
                  &nbsp;    الفريق الأول
                </h3>
                <h3
                  style="text-align: right; right: 50%!important;color: black;position: fixed;top:285px; width: 50%"
                >
                  &nbsp;الفريق الثاني
                </h3>
                <h4
                  style="text-align: right; right: 0%!important;color: black;position: fixed;top:320px; width: 50%"
                >
                  &nbsp; :   الاسم
                </h4>
                <h4
                  v-if="this.items[0]"
                  style="text-align: center; right: 0%!important;color: black;position: fixed;top:320px; width: 50%"
                >
                  {{ items[0].owner_data.name }}
                </h4>
                <h4
                  style="text-align: right; right: 50%!important;color: black;position: fixed;top:320px; width: 50%"
                >
                  &nbsp; :     الاسم
                </h4>
                <h4
                  v-if="this.payer.name"
                  style="text-align: center; right: 60%!important;color: black;position: fixed;top:320px; width: 50%"
                >
                  {{ this.payer.name }}
                </h4>

                <h4
                  style="text-align: right; right: 0%!important;color: black;position: fixed;top:345px; width: 50%"
                >
                  &nbsp; :    الرقم الوطني
                </h4>
                <h4
                  v-if="items[0]"
                  style="text-align: center; right: 0%!important;color: black;position: fixed;top:345px; width: 50%"
                >
                  {{ items[0].owner_data.identify }}
                </h4>
                <h4
                  style="text-align: right; right: 50%!important;color: black;position: fixed;top:345px; width: 50%"
                >
                  &nbsp; :     الرقم الوطني
                </h4>
                <h4
                  v-if=" this.payer.identify"
                  style="text-align: center; right: 60%!important;color: black;position: fixed;top:345px; width: 50%"
                >
                  {{ this.payer.identify }}
                </h4>
                <h4
                  style="text-align: right; right: 0%!important;color: black;position: fixed;top:370px; width: 50%"
                >
                  &nbsp; :   محل ورقم القيد
                </h4>
                <h4
                  v-if="items[0]"
                  style="text-align: center; right: 0%!important;color: black;position: fixed;top:370px; width: 50%"
                >
                  {{ items[0].owner_data.placeNo }}
                </h4>
                <h4
                  style="text-align: right; right: 50%!important;color: black;position: fixed;top:370px; width: 50%"
                >
                  &nbsp; :     محل ورقم القيد
                </h4>
                <h4
                  v-if="this.payer.placeNo"
                  style="text-align: center; right: 60%!important;color: black;position: fixed;top:370px; width: 50%"
                >
                  {{ this.payer.placeNo }}
                </h4>
                <h4
                  style="text-align: right; right: 0%!important;color: black;position: fixed;top:395px; width: 50%"
                >
                  &nbsp; :    هاتف
                </h4>
                <h4
                  v-if="items[0]"
                  style="text-align: center; right: 0%!important;color: black;position: fixed;top:395px; width: 50%"
                >
                  {{ items[0].owner_data.phone }}
                </h4>
                <h4
                  style="text-align: right; right: 50%!important;color: black;position: fixed;top:395px; width: 50%"
                >
                  &nbsp; :     هاتف
                </h4>
                <h4
                  v-if="this.payer.phone"
                  style="text-align: center; right: 60%!important;color: black;position: fixed;top:395px; width: 50%"
                >
                  {{ this.payer.phone }}
                </h4>
                <h4
                  style="text-align: right; right: 0%!important;color: black;position: fixed;top:420px; width: 50%"
                >
                  &nbsp; :   العنوان
                </h4>
                <h4
                  v-if="items[0]"
                  style="text-align: center; right: 10%!important;color: black;position: fixed;top:420px; width: 40%!important; "
                >
                  {{ items[0].owner_data.currentAdres }}
                </h4>
                <h4
                  style="text-align: right; right: 50%!important;color: black;position: fixed;top:420px; width: 50%"
                >
                  &nbsp; :     العنوان
                </h4>

                <h4
                  v-if="this.payer.currentAdres"
                  style="text-align: center; right: 60%!important;color: black;position: fixed;top:420px; width: 40%!important;"
                >
                  {{ this.payer.currentAdres }}
                </h4>
                <h4
                  style="text-align: right; right: 0%!important;color: black;position: fixed;top:465px; width: 100%"
                >
                  &nbsp; نحن الموقع أدناه ممثل مديرية {{ user.company_data.name }}     نقر انه في الساعة  {{ currentHourTime() }}   من يوم   {{ currentDateTime() }}   قد حضر أمامنا في المكتب كلا من الفرقين المذكورين أعلاه وصرح كلا منهما بأن البيع تم مباشرة عن طريق وساطة أو وسيط كما يلي

                </h4>
                <h4
                  style="text-align: right; right: 8%!important;color: black;position: fixed;top:515px; width: 2%"
                >
                  -1

                </h4>
                <h4
                  style="text-align: right; right: 10%!important;color: black;position: fixed;top:515px; width: 100%"
                >
                  &nbsp;    أقر أنا الفريق الأول أنني أملك المركبة المبينة أوصافها أعلاه بموجب بيان البيع الصادر عن مديرية {{ user.company_data.name }} بأنني بعتها  بيعا  <br>&nbsp; قطعيا إلى الفريق الثاني على الوجه المبين أعلاه ببدل  متفق عليه قبضته من كاملا وأجيز له استلام المركبة وفق الحصص<br> المذكورة أعلاه

                </h4>
                <h4
                  style="text-align: right; right: 8%!important;color: black;position: fixed;top:575px; width: 2%"
                >
                  -2

                </h4>
                <h4
                  style="text-align: right; right: 10%!important;color: black;position: fixed;top:575px; width: 100%"
                >
                  &nbsp;    أقر أنا الفريق الثاني أنني اشتريت المركبة المعينة بالفقرة الأولى من هذا الصك بالبدل المتفق عليه والذي سلمته الى الفريق<br>. الأول كلاملا . وطلبت تسجيل ذلك في سجل المركبات وإعطائي رخصة حسب الأصول

                </h4>
                <h4
                  style="text-align: right; right: 8%!important;color: black;position: fixed;top:625px; width: 2%"
                >
                  -3

                </h4>
                <h4
                  style="text-align: right; right: 10%!important;color: black;position: fixed;top:625px; width: 100%"
                >
                  &nbsp;. على هذا الشكل تم الإيجاب والقبول والتسليم فيما بين الفريقين المتعاقدين ووقعا أدناه <br>واستوفى عنه رسوم التسجيل بموجب الايصال المالي رقم .......................   تاريخ  {{ currentDateTime() }}

                </h4>

                <h4
                  style="text-align: right; right: 10%!important;color: black;position: fixed;top:685px; width: 45%"
                >
                  &nbsp; الفريق الأول
                </h4>

                <h4
                  style="text-align: right; right: 55%!important;color: black;position: fixed;top:685px; width: 45%"
                >
                  &nbsp; الفريق الثاني
                </h4>
                <h4
                  style="text-align: right; right: 10%!important;color: black;position: fixed;top:770px; width: 90%"
                >
                  &nbsp;القيود المتممة

                </h4>
                <h4
                  style="text-align: right; right: 10%!important;color: black;position: fixed;top:815px; width: 90%"
                >
                  &nbsp;مكتب التسجيل
                </h4>
                <h3
                  v-if="items[0]"
                  style="text-align: right; right: 10%!important;color: black;position: fixed;top:835px; width: 90%"
                >
                  محضر عقد رقم : {{ items[0].no }} تاريخ :{{ currentDateTime() }} أنا الموقع رئيس تسجيل عقود السيارات أنه في<br>  الساعة {{ currentHourTime() }} قد تم تسجيل مضمون هذه العقود في السجل

                </h3>

                <h4
                  style="text-align: right; right: 10%!important;color: black;position: fixed;top:900px; width: 50%"
                >
                  &nbsp;رئيس مكتب العقود
                </h4>
                <h4
                  style="text-align: right; right: 80%!important;color: black;position: fixed;top:900px; width: 20%"
                >
                  &nbsp;المدير العام للنقل
                </h4>
              </div>

            </b-card>
          </b-col>
        </b-row>
      </b-col>

    </b-row>
  </div>
</template>

<script>

export default {
  data() {
    return {
      errors: [],
      deleteElement: false,
      filter: null,
      payer: {},
      filter1: null,
      other_fee: 0,
      name: null,
      phone: null,
      fee: 0,
      identify: null,
      identification_type: null,
      element: null,
      currentPage: 1,
      totalRows: 0,
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/GetAuth']
    },
    Fee() {
      return this.$store.getters['fee/GetElements']
    },
    Total() {
      let totalfee = 0
      if (this.fee) totalfee = parseInt(this.fee, 10) + parseInt(this.other_fee, 10)
      return totalfee
    },
    types() {
      return [{ key: 'identify', title: this.$t('identifyType') }, { key: 'passport', title: this.$t('passport') }, { key: 'other', title: this.$t('other') }]
    },
    Owners() {
      return this.$store.getters['owner/GetElements']
    },
    items() {
      return this.$store.getters['check/GetElements']
    },
    pagination() {
      return `page=${this.currentPage}&search=${this.filter}`
    },
  },
  watch: {
    'payer.owner': function () {
      this.owner_data = this.SelectedOwner(this.payer.owner)
      this.payer.name = this.owner_data.name
      this.payer.id = this.owner_data.id
      this.payer.adress = this.owner_data.adress
      this.payer.currentAdres = this.owner_data.currentAdres
      this.payer.identification_type = this.owner_data.identification_type
      this.payer.identify = this.owner_data.identify
      this.payer.phone = this.owner_data.phone
      this.payer.placeNo = this.owner_data.placeNo
    },
    currentPage() {
      this.getElement()
    },
  },
  created() {
    this.$store.dispatch('owner/GetElements')
    this.$store.dispatch('fee/GetPluckedElements')
    this.chekker = true
  },
  methods: {
    currentDateTime() {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      const dateTime = `${date}`

      return dateTime
    },
    currentHourTime() {
      const current = new Date()
      const date = `${current.getHours()}:${current.getMinutes()}`
      const dateTime = `${date}`

      return dateTime
    },
    SelectedOwner(val) {
      return this.Owners.find(el => el.id === val)
    },
    async print(itemId) {
      const options = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes',
        ],
        styles: [
          '../../../../assets/scss/certificate.css',
        ],
        timeout: 10, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: itemId, // override the window title
        title: itemId,
      }
      // Pass the element id here
      // await this.$htmlToPaper('printMe', { options })
      if (this.items[0] !== null && this.items[0] !== undefined) {
        await this.$htmlToPaper('printMe', { options, styles: ['/style.css'] })
      } else {
        alert('الرجاء اضافة رخصة ')
      }
    },
    onEnter() {
      this.chekker = false
      this.currentPage = 1
      this.getElement()
      this.fee = this.Fee.payFee
      this.other_fee = 0
    },
    onEnter1() {
      this.chekker = false
      this.currentPage = 1
      this.getElement1()
      this.fee = this.Fee.payFee
      this.other_fee = 0
    },
    // getElement() {
    //   this.$store.dispatch('check/GetElementByCheckNo', `page=${this.currentPage}&search=${this.filter}`).then(response => {
    //     this.totalRows = response.data.paginate.total
    //     this.currentPage = response.data.paginate.current_page
    //   })
    // },
    getElement1() {
      this.$store.dispatch('check/GetElementBychassisNo', `page=${this.currentPage}&search=${this.filter1}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
      })
    },
    create1() {
      this.errors = []
      this.payer.owner_type = 0
      if (this.payer.owner !== null && this.payer.owner !== undefined) {
        this.print()
      } else {
        this.$store.dispatch('owner/Create', this.payer).then(response => {
          this.payer.owner = response.data.message.slice(31)
          this.print()
        }).catch(error => {
          this.errors = error.response.data.errors
        })
      }
    },

    create() {
      this.filter1 = this.items[0].checkin_data.chassis_number
      this.errors = []
      this.items[0].owner = this.payer.owner
      this.items[0].payer = this.payer.owner
      this.items[0].pay_fee = this.fee
      this.items[0].other_fee = this.other_fee
      this.items[0].notes = this.notes
      this.items[0].plate_fee = null
      this.items[0].renew_fee = null
      if ((this.items[0].plate === null || this.items[0].plate === '')) {
        alert('plate is required')
      } else {
        this.$store.dispatch('check/Create', this.items[0]).then(() => {
          this.payer = {}
          this.filter1 = ''
          this.items[0] = null
        }).catch(error => {
          this.errors = error.response.data.errors
        })
      }
    },
  },
}
</script>
